import './Tooltip.css';

// Libraries
import PropTypes from 'prop-types';
import { useRef, useState, Fragment } from 'react';

function Tooltip({
    children,
    tip,
    position = 'bottom-center',
    width = 'auto',
    isDisabled = false,
    textAlign = 'center',
}) {
    const [tooltipPosition, setTooltipPosition] = useState({});
    const childrenRef = useRef(null);

    const onHover = () => {
        if (!childrenRef.current) return;
        const childrenBoundingRect = childrenRef.current.getBoundingClientRect();
        switch (position) {
            case 'right-center':
                setTooltipPosition({
                    top: childrenBoundingRect.height / 2,
                    left: childrenBoundingRect.width + 10,
                });
                break;
            case 'left-center':
                setTooltipPosition({
                    top: childrenBoundingRect.height / 2,
                    right: childrenBoundingRect.width + 10,
                });
                break;
            case 'top-center':
                setTooltipPosition({
                    bottom: childrenBoundingRect.height + 10,
                    left: childrenBoundingRect.width / 2,
                });
                break;
            case 'bottom-center':
            default:
                setTooltipPosition({
                    top: childrenBoundingRect.height + 10,
                    left: childrenBoundingRect.width / 2,
                });
                break;
        }
    };

    return (
        <div className="tooltip">
            <div className="tooltip-child" ref={childrenRef} onMouseEnter={onHover}>
                {children}
            </div>
            {!isDisabled && (
                <span
                    className={`tooltip-text ${position}`}
                    style={{ width, ...tooltipPosition, textAlign }}
                >
                    {Array.isArray(tip)
                        ? tip.map((t, index) => (
                              <Fragment key={index}>
                                  {t}
                                  <br />
                              </Fragment>
                          ))
                        : tip}
                </span>
            )}
        </div>
    );
}

Tooltip.propTypes = {
    children: PropTypes.node,
    tip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    position: PropTypes.oneOf(['bottom-center', 'top-center', 'left-center', 'right-center']),
    width: PropTypes.string,
    textAlign: PropTypes.oneOf([
        'left',
        'right',
        'center',
        'justify',
        'start',
        'end',
        'inherit',
        'initial',
        'unset',
    ]),
};

export default Tooltip;
