import { gql } from './apollo';

export const GET_UNIFIED_RESULTS_FOR_TABLE = gql`
    query (
        $tableId: ID!
        $limit: Int = 100
        $offset: Int = 0
        $filter: String
        $filterValue: String
    ) {
        unifiedResultsForTable(
            tableId: $tableId
            limit: $limit
            offset: $offset
            filter: $filter
            filterValue: $filterValue
        )
    }
`;

export const UNIFY_UNIFIED_RESULTS = gql`
    mutation ($unifiedResultsIds: [String]!, $minScore: Int) {
        unifyUnifiedResults(unifiedResultsIds: $unifiedResultsIds, minScore: $minScore)
    }
`;

export const IGNORE_UNIFIED_RESULTS = gql`
    mutation ($unifiedResultsIds: [String]!) {
        ignoreUnifiedResults(unifiedResultsIds: $unifiedResultsIds)
    }
`;

export const GET_UNIFIED_RESULTS_COUNT = gql`
    query {
        unifiedResultsCount
    }
`;
