import './App.css';

// Libraries
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ApolloProvider, client } from 'utils/graphql/apollo';

// Utils
import User from 'utils/user.util';

// Component & Views
import { RoutesList } from 'components/RoutesList';
import useToken from 'components/useToken';
import Header from 'components/Header/Header';
import Login from 'views/Login/Login';
import ContentWrapper from 'components/ContentWrapper/ContentWrapper';
import TokenRefresh from 'components/Auth/TokenRefresh';
import AccessWrapper from 'components/AccessWrapper/AccessWrapper';
import Logout from 'views/Logout/Logout';

export const PermissionsContext = createContext();

function App() {
    const { token, setToken, isAuth } = useToken();
    const [permissions, setPermissions] = useState([]);

    // Match current path to an unauthenticated route
    const isUnauthenticatedRoute = RoutesList.some((route) => {
        if (!route.unauthenticated) return false;

        const routeParts = route.path.split('/').filter(Boolean);
        const currentPathParts = window.location.pathname.split('/').filter(Boolean);

        // Check if the route matches the current path
        if (routeParts.length !== currentPathParts.length) return false;

        return routeParts.every((part, index) => {
            return part.startsWith(':') || part === currentPathParts[index];
        });
    });

    const getPermissions = useCallback(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/permission`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (Array.isArray(data)) {
                    setPermissions(data);
                }
            })
            .catch((err) => console.log(err));
    }, [token]);

    /**
     * Fetch permissions when authenticated
     **/
    useEffect(() => {
        if (permissions.length === 0 && token) {
            getPermissions();
        }
    }, [permissions, token, getPermissions]);

    /**
     * Token validation on each render, except for unauthenticated routes.
     **/
    useEffect(() => {
        if (isUnauthenticatedRoute) return;

        isAuth()
            .then((auth) => {
                if (!auth) {
                    setToken(null);
                }
            })
            .catch(() => {
                setToken(null);
            });
    }, [isAuth, setToken, isUnauthenticatedRoute]);

    /**
     * Redirect to Login if token is missing and not on an unauthenticated route.
     **/
    if (!token && !isUnauthenticatedRoute) {
        User.clear();
        return <Login setToken={setToken} />;
    }

    return (
        <div className="app">
            <TokenRefresh>
                <PermissionsContext.Provider value={{ permissions }}>
                    {isUnauthenticatedRoute ? (
                        // Render routes without authentication (e.g., unify_results)
                        <ApolloProvider client={client}>
                            <AccessWrapper>
                                <Routes>
                                    {RoutesList.filter((route) => route.unauthenticated).map(
                                        (_route, i) => (
                                            <Route
                                                key={i}
                                                path={_route.path}
                                                element={_route.element}
                                            />
                                        )
                                    )}
                                </Routes>
                            </AccessWrapper>
                        </ApolloProvider>
                    ) : (
                        // Render authenticated routes with full layout
                        <>
                            <Header />
                            <ContentWrapper>
                                <ApolloProvider client={client}>
                                    <AccessWrapper>
                                        <Routes>
                                            {RoutesList.map((_route, i) => {
                                                // Skip unauthenticated routes
                                                if (_route.unauthenticated) return null;

                                                return (
                                                    <Route
                                                        key={i}
                                                        path={_route.path}
                                                        element={_route.element}
                                                    />
                                                );
                                            })}
                                            <Route
                                                path="/logout"
                                                element={<Logout setToken={setToken} />}
                                            />
                                        </Routes>
                                    </AccessWrapper>
                                </ApolloProvider>
                            </ContentWrapper>
                        </>
                    )}
                </PermissionsContext.Provider>
            </TokenRefresh>
        </div>
    );
}

export default App;
