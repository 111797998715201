import './UnifyOrIgnoreModal.css';

// Libraries
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

// Components
import Button from 'components/Buttons/Button/Button';
import Modal from 'components/Modal/Modal';
import Pill from 'components/Pill/Pill';

// Utils
import { formatNumber, ucWords } from 'utils/text.util';
import Slider from 'components/Slider/Slider';

// Assets
import { ReactComponent as ExpandMoreIcon } from 'assets/icons/expand_more_24.svg';
import { ReactComponent as ExpandLessIcon } from 'assets/icons/expand_less_icon_24.svg';

const UnifyOrIgnoreModal = ({
    minThreshold,
    isOpen,
    setIsOpen,
    unifiedResults,
    merge,
    ignore,
    table = '',
}) => {
    const [threshold, setThreshold] = useState(minThreshold);
    const [unifedResultsToMerge, setUnifedResultsToMerge] = useState([...unifiedResults]);
    const [extraMatches, setExtraMatches] = useState([]);
    const [showViewMoreBtn, setShowViewMoreBtn] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [isOverflowAuto, setIsOverflowAuto] = useState(false);
    const [hoverStates, setHoverStates] = useState({});

    const numbMatchesToMerge = unifedResultsToMerge.length;

    const toggleShowDetails = () => {
        setShowDetails((prev) => !prev);
    };

    const ignoreUnifiedResults = () => {
        setIsOpen(false);
        ignore(unifiedResults.map((unifiedResult) => unifiedResult.id));
    };

    const mergeUnifiedResults = () => {
        setIsOpen(false);
        merge(
            unifiedResults.map((unifiedResult) => unifiedResult.id),
            threshold
        );
    };

    const filterUnifiedResultsByScore = useCallback(
        (threshold) => {
            setUnifedResultsToMerge(
                unifiedResults
                    .filter((ur) => ur.score >= threshold)
                    .sort((a, b) => a.score - b.score)
            );
        },
        [unifiedResults]
    );

    const DetailsBtn = (
        <div className="details-btn" onClick={toggleShowDetails}>
            {showDetails ? (
                <>
                    <ExpandLessIcon /> Hide Details
                </>
            ) : (
                <>
                    <ExpandMoreIcon /> View Details
                </>
            )}
        </div>
    );

    const showMoreMatches = () => {
        setExtraMatches((prev) => {
            const numbOfExtraMatches = prev.length;
            const startingIndex = 1 + numbOfExtraMatches;

            // Calculate the ending index, ensuring it doesn't exceed the length of the array
            const endingIndex =
                startingIndex + 3 >= unifedResultsToMerge.length - 2
                    ? unifedResultsToMerge.length - 1
                    : startingIndex + 3;

            return prev.concat(unifedResultsToMerge.slice(startingIndex, endingIndex));
        });
    };

    const handlePillColMouseEnter = (rowIndex, colIndex) => {
        setHoverStates((prev) => ({
            ...prev,
            [rowIndex]: { ...prev[rowIndex], [colIndex]: true },
        }));
    };

    const handlePillColMouseLeave = (rowIndex, colIndex) => {
        setHoverStates((prev) => ({
            ...prev,
            [rowIndex]: { ...prev[rowIndex], [colIndex]: false },
        }));
    };

    useEffect(() => {
        filterUnifiedResultsByScore(threshold);
    }, [threshold, filterUnifiedResultsByScore]);

    useEffect(() => {
        setThreshold(minThreshold);
        filterUnifiedResultsByScore(minThreshold);
    }, [isOpen, minThreshold, filterUnifiedResultsByScore]);

    useEffect(() => {
        if (!unifiedResults) return;
        if (numbMatchesToMerge <= 2) {
            setShowViewMoreBtn(false);
        } else {
            const numbOfHiddenMatches = numbMatchesToMerge - 2 - extraMatches.length;
            setShowViewMoreBtn(numbOfHiddenMatches > 0);
        }
    }, [unifiedResults, numbMatchesToMerge, extraMatches]);

    useEffect(() => {
        setExtraMatches([]);
    }, [isOpen, numbMatchesToMerge]);

    useEffect(() => {
        setShowDetails(false);
    }, [isOpen]);

    const unifiedResultRow = (unifiedResult, rowIndex) => (
        <div className="match-row" key={rowIndex}>
            <div className="match-score">{parseFloat(unifiedResult.score.toFixed(2))}</div>
            {unifiedResult.unified_result_attributes.map((attribute, j) => (
                <div
                    className="attr-col"
                    key={j}
                    onMouseEnter={() => handlePillColMouseEnter(rowIndex, j)}
                    onMouseLeave={() => handlePillColMouseLeave(rowIndex, j)}
                >
                    <Pill width="200px" isExpanded={hoverStates[rowIndex]?.[j] === true}>
                        {attribute.value}
                    </Pill>
                    <Pill width="200px" isExpanded={hoverStates[rowIndex]?.[j] === true}>
                        {attribute.compared_value}
                    </Pill>
                </div>
            ))}
        </div>
    );

    const headerRow = (unifiedResult) => (
        <div className="header-row">
            <div className="attr-header score-header">Score</div>
            {unifiedResult.unified_result_attributes.map((attribute, i) => (
                <div className="attr-header" key={`header-${i}`}>
                    {attribute.field} ({attribute.weight})
                </div>
            ))}
        </div>
    );

    return (
        <Modal
            isOpen={isOpen}
            setOpen={setIsOpen}
            options={{
                type: 'custom',
                width: 'auto',
                minWidth: '700px',
                title: 'Merge or Ignore Duplicate Matches',
                className: 'unify-ignore-modal',
                textAlign: 'center',
                content: (
                    <>
                        <div className="table-name">
                            <span className="table-name-header">Table Name:</span>
                            {ucWords(table)}
                        </div>
                        {unifiedResults.length === 1 ? (
                            <>
                                {headerRow(unifiedResults[0])}
                                {unifiedResultRow(unifiedResults[0], 0)}
                            </>
                        ) : (
                            <>
                                <div className="slider-container">
                                    <div>Select Min. Unify Score: {threshold}%</div>
                                    <Slider
                                        min={minThreshold}
                                        max={100}
                                        value={threshold}
                                        width="60%"
                                        onChange={(value) => setThreshold(value)}
                                        displayValue={false}
                                    />
                                    <div>
                                        <span
                                            className="numb-matches"
                                            style={{
                                                color:
                                                    numbMatchesToMerge === 0
                                                        ? 'var(--error-color'
                                                        : 'var(--font-color)',
                                            }}
                                        >
                                            {formatNumber(numbMatchesToMerge)}/
                                            {formatNumber(unifiedResults.length)}
                                        </span>{' '}
                                        matches will be merged.
                                    </div>
                                </div>
                                {numbMatchesToMerge > 0 && DetailsBtn}
                                <div
                                    className={`matches-container ${showDetails ? 'expanded' : ''}`}
                                    onTransitionEnd={() => setIsOverflowAuto(showDetails)}
                                    style={{
                                        overflowY:
                                            isOverflowAuto && showDetails ? 'auto' : 'hidden',
                                    }}
                                >
                                    {numbMatchesToMerge > 0 && (
                                        <>
                                            {headerRow(unifiedResults[0])}
                                            {unifedResultsToMerge &&
                                                unifedResultsToMerge[0] &&
                                                unifiedResultRow(unifedResultsToMerge[0], 0)}
                                            {extraMatches &&
                                                extraMatches.map((match, i) =>
                                                    unifiedResultRow(match, i + 1)
                                                )}
                                            {showViewMoreBtn && (
                                                <div className="match-row">
                                                    <Button
                                                        width="auto"
                                                        variant="secondary"
                                                        onClick={showMoreMatches}
                                                    >
                                                        <div className="expand-btn">
                                                            <ExpandMoreIcon />
                                                            View more matches
                                                            <ExpandMoreIcon />
                                                        </div>
                                                    </Button>
                                                </div>
                                            )}
                                            {unifedResultsToMerge &&
                                                unifedResultsToMerge.length > 1 &&
                                                unifiedResultRow(
                                                    unifedResultsToMerge[
                                                        unifedResultsToMerge.length - 1
                                                    ],
                                                    unifedResultsToMerge.length - 1
                                                )}
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                ),
                footer: (
                    <div className="unify-ignore-button-row">
                        <Button variant="secondary" width="120px" onClick={ignoreUnifiedResults}>
                            Ignore All
                        </Button>
                        <Button
                            width="120px"
                            onClick={mergeUnifiedResults}
                            isDisabled={numbMatchesToMerge === 0}
                        >
                            Merge
                        </Button>
                    </div>
                ),
            }}
        />
    );
};

UnifyOrIgnoreModal.propTypes = {
    minThreshold: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    unifiedResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    table: PropTypes.string,
    merge: PropTypes.func.isRequired,
    ignore: PropTypes.func.isRequired,
};

export default UnifyOrIgnoreModal;
