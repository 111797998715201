import { useState } from 'react';

export const TOKEN_NAMESPACE = 'okzou-cdp-token';
export const TOKEN_EXP_NAMESPACE = 'okzou-cdp-exp';
export const AUTH_CHECK_TIMESTAMP = 'okzou-cdp-auth-check';

export default function useToken() {
    const getToken = () => {
        // Check if token is passed via URL
        const urlParams = new URLSearchParams(window.location.search);
        const urlToken = urlParams.get('token');

        if (urlToken) {
            console.log('USETOKEN.JS :: Token found in URL.', urlToken);
            return urlToken;
        }

        // Fallback to token stored in localStorage
        const token = localStorage.getItem(TOKEN_NAMESPACE);
        const exp = localStorage.getItem(TOKEN_EXP_NAMESPACE);

        // Check locally if token is expired
        const timestamp = new Date().getTime();
        if (exp && exp < timestamp) {
            console.log('USETOKEN.JS :: Token Expired.', exp);
            return null;
        }

        return token;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken) => {
        if (userToken) {
            // LOGIN
            localStorage.setItem(TOKEN_NAMESPACE, userToken?.token);
            localStorage.setItem(TOKEN_EXP_NAMESPACE, userToken?.expires * 1000);
            setToken(userToken?.token);

            console.log(`Token will expire at ${new Date(userToken?.expires * 1000)}`);
        } else {
            // LOGOUT
            localStorage.removeItem(TOKEN_NAMESPACE);
            localStorage.removeItem(TOKEN_EXP_NAMESPACE);
            setToken(null);
        }
    };

    const refreshToken = async () => {
        const _token = getToken();
        const exp = localStorage.getItem(TOKEN_EXP_NAMESPACE);
        const timestamp = new Date().getTime();

        // If within 5 minutes of expiring, refresh
        if (exp && exp < timestamp + 60 * 15 * 1000) {
            const newToken = await fetch(`${process.env.REACT_APP_BACKEND_URL}/refresh_token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${_token}`,
                },
            })
                .then((res) => res.json())
                .catch(() => saveToken(null));
            saveToken(newToken);
        }
    };

    const isAuthenticated = async () => {
        const authCheck = localStorage.getItem(AUTH_CHECK_TIMESTAMP);
        const timestamp = new Date();

        // Skip if checked within 5 minutes
        if (authCheck && authCheck < timestamp.getTime()) return true;

        const _token = getToken();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/loggedin`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${_token}`,
            },
        });

        // Set timestamp for last check so that we don't spam this verification
        const timestamp_minus_five = timestamp.setMinutes(timestamp.getMinutes() - 5);
        localStorage.setItem(AUTH_CHECK_TIMESTAMP, timestamp_minus_five);

        return response?.status === 200;
    };

    return {
        isAuth: isAuthenticated,
        setToken: saveToken,
        refresh: refreshToken,
        token,
    };
}
