import Dashboard from 'views/Dashboard/Dashboard';
import Account from 'views/Account/Account';
import Apps from 'views/Apps/Apps';
import { default as UserApp } from 'views/Apps/App/App';
import { default as AppsImport } from 'views/Apps/Import/Import';
import Database from 'views/Database/Database';
import Table from 'views/Database/Table/Table';
import Import from 'views/Import/Import';
import Consent from 'views/Consent/Consent';
import Unify from 'views/Unify/Unify';
import Segmentation from 'views/Segmentation/Segmentation';
import Settings from 'views/Settings/Settings';
import Segment from 'views/Segmentation/Segment/Segment';
import Builder from 'views/Apps/Builder/Builder';
import ObjectEditor from 'views/Database/ObjectEditor/ObjectEditor';
import Flow from 'views/Segmentation/Flow/Flow';
import Download from 'views/Segmentation/Download/Download';
import ComponentsDemo from 'views/ComponentsDemo/ComponentsDemo';
import KpiBuilder from 'views/Kpis/KpiBuilder/KpiBuilder';
import Kpis from 'views/Kpis/Kpis';
import UnifySetup from 'views/Unify/UnifySetup/UnifySetup';
import UnifyResults from 'views/Unify/UnifyResults/UnifyResults';

export const RoutesList = [
    {
        path: '/',
        element: <Dashboard />,
    },
    {
        path: '/account',
        element: <Account />,
    },
    {
        path: '/apps',
        element: <Apps />,
    },
    {
        path: '/apps/new',
        element: <Builder />,
    },
    {
        path: '/apps/edit/:app_id',
        element: <Builder />,
    },
    {
        path: '/apps/:app',
        element: <UserApp />,
    },
    {
        path: '/apps/import',
        element: <AppsImport />,
    },
    {
        path: '/consent',
        element: <Consent />,
    },
    {
        path: '/consent/:segment_id',
        element: <Segment />,
    },
    {
        path: '/database',
        element: <Database />,
    },
    {
        path: '/database/new',
        element: <ObjectEditor />,
    },
    {
        path: '/database/edit/:table_id',
        element: <ObjectEditor />,
    },
    {
        path: '/database/:table',
        element: <Table />,
    },
    {
        path: '/import',
        element: <Import />,
    },
    {
        path: '/segmentation',
        element: <Segmentation />,
    },
    {
        path: '/segmentation/flow/new/:segment_id',
        element: <Flow />,
    },
    {
        path: '/segmentation/flow/:flow_id/:segment_id',
        element: <Flow />,
    },
    {
        path: '/segmentation/download/:segment_id',
        element: <Download />,
    },
    {
        path: '/segmentation/:segment_id',
        element: <Segment />,
    },
    {
        path: '/unify',
        element: <Unify />,
    },
    {
        path: '/unify/:unify_id',
        element: <UnifySetup />,
    },
    {
        path: '/unifyresults/:table_id',
        element: <UnifyResults />,
    },
    {
        path: '/unify_results/:table_id',
        element: <UnifyResults />,
        unauthenticated: true,
    },
    {
        path: '/kpis',
        element: <Kpis />,
    },
    {
        path: '/kpis/:kpi_id',
        element: <KpiBuilder />,
    },
    {
        path: '/settings/*',
        element: <Settings />,
    },
    {
        path: '/demo',
        element: <ComponentsDemo />,
    },
];
